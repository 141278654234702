<script setup lang="ts"></script>

<template>
  <div class="container">
    <div class="cross"></div>
    <div class="message">
      <slot name="body"></slot>
    </div>
    <slot name="footer"></slot>
  </div>
</template>
<style lang="scss" scoped>
$cross-size: 80px;
.container {
  max-width: 980px;
  width: 100%;
  margin: 0 auto;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 100px;

  .cross {
    width: $cross-size;
    height: $cross-size;

    color: var(--vt-p-attention);
    border: 1px solid var(--vt-p-attention);
    border-radius: 50%;
    border-width: medium;
    position: relative;
    left: calc(50% - ($cross-size / 2));

    &::before {
      content: '\0D7';
      font-size: 40px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-40%) translateX(-25%);
      width: inherit;
      height: inherit;
    }
  }
  .message {
    color: var(--vt-p-attention);
    font-weight: bold;
    text-align: center;
    margin: 3rem 0;
  }
}
</style>
